@tailwind base;
@tailwind components;
@tailwind utilities;

@media only screen and (min-width: 0px) and (max-width: 700px) {
  tr,
  td {
    display: inline-block;
    padding: 5px;
    width: 100%;
  }
}
